/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useCallback } from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import chunk from 'lodash.chunk';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';
// import DemoModal from 'components/DemoModal';

// import { Main } from 'styles/blog';
// import { VideoPost } from 'styles/videoPost';
import 'styles/blog.scss';

const ProductUpdates = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => {
  const [posts, setPosts] = useState([]);

  const [pages, setPages] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const [category, setCategory] = useState('Product Updates');

  const postsPerPage = 21;

  // eslint-disable-next-line no-unused-vars
  const categoryToggle = useCallback(
    (input) => () => {
      if (input !== category) {
        setCurrentPage(0);
        setPosts([]);
        setPages([]);
      }
      setCategory(() => input);
    },
    [],
  );

  const getBlogPosts = useCallback(
    () =>
      allPosts.filter((p) => {
        if (category) {
          if (p.node.category?.some((e) => e.name === category)) {
            setPosts([]);
            return true;
          }
          setPosts([]);
          return false;
        }
        setPosts([]);
        return true;
      }),
    [allPosts, category],
  );

  useEffect(() => {
    setPages(chunk(getBlogPosts(), postsPerPage));
  }, [allPosts, category]);

  useEffect(() => {
    setPosts((state) => [
      ...state,
      ...(pages[currentPage] ? pages[currentPage] : []),
    ]);
  }, [pages, currentPage]);

  const loadMore = () => {
    if (currentPage < pages.length) {
      setCurrentPage((state) => state + 1);
    }
  };

  // const [youtubeData, setYoutubeData] = useState([]);

  // useEffect(() => {
  //   let isMounted = true;
  //   fetch(
  //     `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${process.env.GATSBY_PLAYLIST_ID}&key=${process.env.GATSBY_YOUTUBE_API_KEY}`,
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (isMounted) {
  //         setYoutubeData(data.items);
  //       }
  //     });

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  // const [showVideoModal, setVideoModal] = useState(false);
  // const [demoVideo, setDemoVideo] = useState('https://www.youtube-nocookie.com/embed/vrff3vYs93I?controls=0');

  // const toggleVideoModal = useCallback((url) => {
  //   setDemoVideo(`https://www.youtube-nocookie.com/embed/${url}?controls=0`)
  //   setVideoModal(!showVideoModal);
  // },[showVideoModal])

  // const clickHandler = useCallback((e) => {
  //   if (e.target.id === 'demo-modal') setVideoModal(false);
  // },[])

  // useEffect(() => {
  //   window.addEventListener('click', clickHandler);

  //   return () => window.removeEventListener('click', clickHandler);
  // }, [clickHandler]);

  // useEffect(() => {
  //   document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  // }, [showVideoModal]);

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Product Updates | ruttl"
            description="Stay updated with new product features, improvements and innovations through ruttl's product updates page."
            url="https://ruttl.com/product-updates/"
          />
          {/* <DemoModal id="demo-modal" show={showVideoModal}>
            <button
              type="button"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src={demoVideo}
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </DemoModal> */}
          <main className="blog-styled-main">
            <div className="blog-filters-main">
              <div className="container">
                <div className="filter-block">
                  <Link to="/blog/">All blogs</Link>
                  <Link to="/product-updates/" className="active">
                    Product Updates
                  </Link>
                  <Link to="/product-videos/">Product Videos</Link>
                  <Link to="/customer-stories/">Customer Stories</Link>
                </div>
                <h1 className="product-updates-heading">Product Updates</h1>
                {/* <div className="filter-block">
                  <button
                    className={`${category === 'Product Updates' ? 'active' : ''}`}
                    type="button"
                    onClick={categoryToggle('Product Updates')}>
                    Product Updates
                  </button>
                  <button
                    className={`${category === 'Product Videos' ? 'active' : ''}`}
                    type="button"
                    onClick={categoryToggle('Product Videos')}>
                    Product Videos
                  </button>
                </div> */}
              </div>
            </div>
            <section className="post-list-main product-posts">
              {/* <div className="container">
              <p className="bold post-list-main--subhead">
                Our thoughts and observations on business, partners, nutrients,
                productivity, Science, &amp; more.
              </p>
            </div> */}
              {true && (
                <div className="container">
                  <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                    {posts.map(({ node }) => (
                      <React.Fragment key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                          image={node.archiveThumbnail.fluid}
                          excerpt={node.excerpt.excerpt}
                          category={node.category}
                          noDetail
                          date={node.publishDate}
                          author={node.author.name}
                          authorImage={node.author.image.fluid}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  {currentPage + 1 < pages.length ? (
                    <div style={{ marginTop: '20px' }}>
                      <button
                        type="button"
                        className="button"
                        onClick={loadMore}>
                        Load more
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </section>
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

ProductUpdates.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ProductUpdates;

export const pageQuery = graphql`
  query ProductUpdateQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        category: {
          elemMatch: { name: { in: ["Product Updates", "Product Videos"] } }
        }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          category {
            name
          }
          author {
            id
            name
            image {
              fluid(
                maxWidth: 32
                maxHeight: 32
                quality: 100
                resizingBehavior: THUMB
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
